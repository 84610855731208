import React from "react";
import { graphql, useStaticQuery } from "gatsby";

// import { nanoid } from "nanoid";

import Img from "gatsby-image";

// import axios from 'axios';

import Layout from "../components/layout";
import SEO from "../components/seo";

function Feedback() {
  // const [pods, setPods] = useState([]);

  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "feedb" } }) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            name
            id
          }
        }
      }
    }
  `);
  const { edges } = allFile;

  // useEffect(() => {
  //   const getPods = async () => {
  //     const response = await axios.get(
  //       'https://wp-api.proff86.ru/wp-json/wp/v2/feedback'
  //     );
  //     console.log('@@@', response.data);
  //     setPods(response.data);
  //   };
  //   getPods();
  // }, []);

  return (
    <Layout>
      <SEO
        keywords={[`ЧОУ Профф`, `отзывы`, `благодарственные письма`]}
        title="Отзывы"
      />

      <section className="flex flex-col items-center ">
        <h1 className=" text-center text-2xl font-bold uppercase tracking-wider my-4 text-green-800 ">
          Отзывы и благодарственные письма
        </h1>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {/* <pre>{JSON.stringify(edges, null, 2)}</pre> */}
          {edges.map(({ node }) => (
            <div
              key={node.id}
              className="
            transform hover:scale-105 transition 
              ease-in-out duration-700 w-56"
            >
              <a
                href={node.childImageSharp.fluid.src}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={node.childImageSharp.fluid} />
              </a>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
}

export default React.memo(Feedback);
